import Bugsnag from "@bugsnag/js";

const apiKey = "0709918e5e0ec2d8bfad9d6575186e3a";

export const notifyBug = (error: any) => {
  if (apiKey) {
    Bugsnag.notify(error);
  } else {
    console.error("Bugsnag notify skipped (not in production):", error);
  }
};
