import { AxiosError } from 'axios';
import { router } from '@/plugins/1.router';
import { useNotificationStore } from '@/store/notification'
import { userStore } from '@/store/user'

const errorMessages = {
    'phone must be a valid phone number': {
        title: 'Telefone inválido',
        message: 'Verifique o número digitado e tente novamente',
    },
    'userName must be longer than or equal to 4 characters': {
        title: 'Senha inválida',
        message: 'O nome de usuário deve conter pelo menos 4 caracteres',
    },
    'userName should not be empty': {
        title: 'Erro x',
        message: 'Erro x',
    },
    'email must be an email': {
        title: 'E-mail inválido',
        message: 'Verifique o e-mail digitado e tente novamente',
    },
    'Password must have at least 1 number': {
        title: 'Senha inválida',
        message: 'A senha deve conter pelo menos 1 número',
    },
    'password must be longer than or equal to 6 characters': {
        title: 'Senha inválida',
        message: 'A senha deve conter pelo menos 6 caracteres',
    },
    'Password must have at least 1 special character': {
        title: 'Senha inválida',
        message: 'A senha deve conter pelo menos 1 caractere especial',
    },
    'Password must have at least 1 lowercase letter': {
        title: 'Senha inválida',
        message: 'A senha deve conter pelo menos 1 letra minúscula',
    },
    'The password must have at least 1 uppercase letter': {
        title: 'Senha inválida',
        message: 'A senha deve conter pelo menos 1 letra maiúscula',
    },
    'userName must be shorter than or equal to 20 characters': {
        title: 'Nome muito longo',
        message: 'A senha deve conter no máximo 20 caracteres',
    },
    'password must be shorter than or equal to 20 characters': {
        title: 'Senha muito longa',
        message: 'A senha deve conter no máximo 20 caracteres',
    },
    'E-mail is already in use': {
        title: 'E-mail já cadastrado',
        message: 'Utilize outro e-mail para continuar',
    },
    'Token is invalid': {
        title: 'Token inválido',
        message: 'Verifique o token e tente novamente',
    },
    'There is no token for this email': {
        title: 'Token não encontrado',
        message:'O token de redefinição de senha expirou ou é inválido',
    }
}

export function errorHandler(axiosError: AxiosError) {
    const notificationStore = useNotificationStore()
    if (!axiosError.response) {
      notificationStore.notifyFail({
        title: "Erro ao conectar ao servidor",
        text: "Tente novamente mais tarde",
      });
      console.error(axiosError);
      return Promise.reject(axiosError);
    }
    const { error, statusCode, message } = axiosError.response?.data

    let title = null
    let text = null

    switch (statusCode) {
        case 401: {
            title = 'Erro ao autenticar'
            text = 'Verifique suas credenciais e tente novamente'
            const user = userStore()
            user.logout()
            break
        }
        case 403: {
            notificationStore.notifyWarning({
              title: "Sem permissão",
              text: "Você não tem permissão para acessar este recurso",
            });

            break
        }
        case 428: {
            notificationStore.notifyWarning({
                title: 'E-mail não verificado',
                text: 'Valide seu celular para continuar',
            })

            router.push('/auth/confirm-code')
            break
        }
        case 429: {
            title = 'Muitas tentativas'
            text = 'Você realizou muitas tentativas, tente novamente mais tarde'
            break
        }
        default: {
            if (Array.isArray(message)) {
                message.forEach((msg) => {
                    if (errorMessages[msg]) {
                        const eachTitle = errorMessages[msg].title
                        const eachText = errorMessages[msg].message
                        notificationStore.notifyFail({ title: eachTitle, text: eachText })
                    }
                })
            }
            else {
                if (errorMessages[message]) {
                    title = errorMessages[message].title ?? 'Opss...'
                    text = errorMessages[message].message ?? 'Não foi possível realizar a operação'
                }
            }
        }
    }

    if (title && text) {
        notificationStore.notifyFail({ title, text })
    }

    console.error(message)
    return Promise.reject(axiosError.response?.data)
}
