import socket from "./socketConfig";
import calendarHandler from "./context/calendarHandler";
import userHandler from "./context/userHandler";
import { userStore } from "@/store/user";

const reconnectInterval = 5000;

function getAuthToken(): string | null {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user).token : null;
  return token !== "null" ? token : null;
}

export default {
  connect(): void {
    const authToken = getAuthToken();
    if (socket.connected || !authToken) return;
    socket.io.opts.transportOptions.polling.extraHeaders.Authorization =
      authToken;

    console.log("Connecting to WebSocket...");
    socket.connect();

    socket.on("connect", () => {
      console.log("Connected to WebSocket");
      calendarHandler.setupEventListeners();
      userHandler.setupEventListeners();
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket");
      calendarHandler.removeEventListeners();
      userHandler.removeEventListeners();
      this.reconnect();
    });

    socket.on("unauthorized", () => {
      console.log("Socket unauthorized");
      const user = userStore();
      user.logout();
    });
  },

  disconnect(): void {
    if (!socket.connected) return;
    console.log("Disconnecting from WebSocket");
    calendarHandler.removeEventListeners();

    socket.disconnect();
  },

  reconnect(): void {
    setTimeout(() => {
      this.connect();
    }, reconnectInterval);
  },
};
