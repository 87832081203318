import { defineStore } from "pinia";

export enum NotificationType {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  FAIL = "FAIL",
}

export interface Notification extends Notify {
  type: NotificationType;
  uuid?: string;
}

export interface Notify {
  title: string;
  text: string;
}

export const useNotificationStore = defineStore("notification", {
  state: (): { notifications: Notification[] } => ({
    notifications: [],
  }),
  actions: {
    addNotification(notification: Notification): void {
      // Verifica se já existe uma notificação igual na lista
      const exists = this.notifications.some(
        (n) =>
          n.title === notification.title &&
          n.text === notification.text &&
          n.type === notification.type
      );

      if (!exists) {
        if (this.notifications.length >= 3) {
          this.notifications.shift();
        }
        const newNotification = { ...notification, uuid: this.generateUUID() };
        this.notifications.push(newNotification);
        setTimeout(() => {
          this.removeNotification(newNotification);
        }, 4000);
      }
    },
    notifySuccess(notify: Notify): void {
      this.addNotification({ ...notify, type: NotificationType.SUCCESS });
    },
    notifyWarning(notify: Notify): void {
      this.addNotification({ ...notify, type: NotificationType.WARNING });
    },
    notifyFail(notify: Notify): void {
      this.addNotification({ ...notify, type: NotificationType.FAIL });
    },
    removeNotification(notification: Notification): void {
      this.notifications = this.notifications.filter(
        (n: Notification) => n.uuid !== notification.uuid
      );
    },
    generateUUID(): string {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
  },
  getters: {
    getNotifications(): Notification[] {
      return this.notifications;
    },
  },
});
