import { defineStore } from "pinia";
import axiosIns from "../composables/axios";
import { router } from "@/plugins/1.router";

import websocket from "@/websocket";

import { useNotificationStore } from "@/store/notification";
import { notifyBug } from "../utils/notifyBug";
import { addUserMetadata } from "@/plugins/bugsnag";
import { AmplitudeService } from "../services/amplitude";

export interface IUser {
  userId: number;
  name: string;
  email: string;
  phone: string;
  role: string;
  permissions: any[];
  owner: boolean;
  validated: boolean;
  companyId: number;
  token: string;
}

export interface NewUserRegisterDTO {
  userName: string;
  email: string;
  password: string;
  whatsapp: string;
}

export interface UserLoginDTO {
  email: string;
  password: string;
}

export interface ActiveAccountDTO {
  email: string;
  token: string;
}

export interface ResetPasswordDTO {
  token: string;
  email: string;
  password: string;
}

export const userStore = defineStore("user", {
  state: (): { user: IUser | null; loading: boolean; plan: string } => ({
    user: JSON.parse(localStorage.getItem("user") as string) || null,
    loading: false,
    plan: "free",
  }),
  actions: {
    async login(UserLoginDTO: UserLoginDTO): Promise<void> {
      const isNotValidated = await this.checkAccountValidationByEmail(
        UserLoginDTO.email
      );

      if (isNotValidated) {
        return;
      }
      await axiosIns
        .post("login", UserLoginDTO)
        .then((res: Promise<Response>) => {
          this.user = res?.data;
          localStorage.setItem("user", JSON.stringify(res?.data));
          addUserMetadata(res.data);
          router.push("/");
        });
    },
    async checkFirstAccess(): Promise<void> {
      const { token } = JSON.parse(localStorage.getItem("user") as string);
      if (token && this.user?.firstAccess) {
        await axiosIns
          .get(`user/first-access`)
          .then((res: Promise<Response>) => {
            this.user.firstAccess = res?.data?.firstAccess;
            localStorage.setItem("user", JSON.stringify(this.user));
            addUserMetadata(this.user);
          })
          .catch((error) => {
            console.error("Error getting first access:", error);
          });
      }
    },
    logout(): void {
      this.user = null;
      localStorage.removeItem("user");
      websocket.disconnect();
      router.push("/auth/login");
    },
    async updateUserInfo(): Promise<void> {
      const user = JSON.parse(localStorage.getItem("user") as string);
      const id = user?.userId ?? user?.id;

      await axiosIns.get(`user/${id}`).then((res: Promise<Response>) => {
        const { token } = JSON.parse(localStorage.getItem("user") as string);
        const logged = { ...res.data, token };
        this.user = logged;

        localStorage.setItem("user", JSON.stringify(logged));
      });
    },
    async register(NewUserRegisterDTO: NewUserRegisterDTO): Promise<void> {
      localStorage.setItem(
        "user",
        JSON.stringify({
          email: NewUserRegisterDTO.email,
        })
      );
      const isNotValidated = await this.checkAccountValidationByEmail(
        NewUserRegisterDTO.email
      );

      if (isNotValidated) {
        return;
      }

      await axiosIns
        .post("register", NewUserRegisterDTO)
        .then((res: Promise<Response>) => {
          localStorage.setItem("user", JSON.stringify(res?.data));
          localStorage.setItem(
            "register-password",
            NewUserRegisterDTO.password
          );
          addUserMetadata(res.data);
          router.push("/auth/confirm-code");
          const notificationStore = useNotificationStore();
          notificationStore.notifySuccess({
            title: "Sucesso",
            text: "Conta criada com sucesso",
          });
          AmplitudeService.trackEvent("Register Success", res.data);
        })
        .catch((error) => {
          console.error(error);
          AmplitudeService.trackEvent("Register Error", {
            error,
          });
        });
    },
    async activateAccount(ActiveAccountDTO: ActiveAccountDTO): Promise<void> {
      this.loading = true;
      await axiosIns
        .put(`validation_account/activate/`, ActiveAccountDTO)
        .then(() => {
          this.user = { ...this.user, validated: true };
          localStorage.setItem("user", JSON.stringify(this.user));
          const notificationStore = useNotificationStore();
          notificationStore.notifySuccess({
            title: "Conta ativada",
            text: "Validamos seu telefone com sucesso",
          });
          this.loading = false;

          const password = localStorage.getItem("register-password");
          if (password) {
            localStorage.removeItem("register-password");
            this.login({ email: ActiveAccountDTO.email, password });
          } else {
            router.push("/auth/login");
          }
        })
        .catch((error) => {
          const notificationStore = useNotificationStore();
          notificationStore.notifyFail({
            title: "Erro",
            text: "Não foi possível ativar a conta",
          });
          this.loading = false;
          console.log(error);
        });
    },
    async checkAccountValidationByEmail(email: string): Promise<boolean> {
      const status = await axiosIns
        .get(`validation_account/status/email/${email}`)
        .then((res) => res.data.status);

      const isNotValidated = status === "NOT_VALIDATED";
      console.log({ status, isNotValidated });
      if (isNotValidated) {
        console.log("deveria redirecionar");
        router.push("/auth/confirm-code");
      }
      return isNotValidated;
    },
    async resendToken(email: string): Promise<void> {
      this.loading = true;
      await axiosIns
        .post(`validation_account/send_token_again`, { email })
        .then(() => {
          const notificationStore = useNotificationStore();
          notificationStore.notifySuccess({
            title: "Código enviado",
            text: "Confirme o código recebido em seu Whatsapp",
          });
          this.loading = false;
        })
        .catch((error) => {
          const notificationStore = useNotificationStore();
          notificationStore.notifyFail({
            title: "Erro",
            text: "Não foi possível ativar a conta",
          });
          this.loading = false;
          console.log(error);
        });
    },
    async resetPassword(ResetPasswordDTO: ResetPasswordDTO): Promise<void> {
      await axiosIns.post("reset_password", ResetPasswordDTO).then(() => {
        const notificationStore = useNotificationStore();
        notificationStore.notifySuccess({
          title: "Sucesso",
          text: "Siga as instruções enviadas para o seu email.",
        });
        router.push("/auth/login");
      });
    },
    async forgotPassword(email: string): Promise<void> {
      await axiosIns.post("forgot_password", { email }).then(() => {
        const notificationStore = useNotificationStore();
        notificationStore.notifySuccess({
          title: "Sucesso",
          text: "Siga as instruções enviadas para o seu email.",
        });
      });
    },
    async uploadUserImg(croppedImageBlob: Blob, UserId: number | null) {
      const formData = new FormData();
      formData.append("file", croppedImageBlob, "user-profile.jpeg");

      const url = UserId
        ? `user/profile-picture/${UserId}`
        : "user/profile-picture";

      return axiosIns
        .put(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const notificationStore = useNotificationStore();
          notificationStore.notifySuccess({
            title: "Sucesso",
            text: "Imagem salva com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          console.error("Erro ao fazer upload da imagem:", error);
          const notificationStore = useNotificationStore();
          notificationStore.notifyFail({
            title: "Erro",
            text: "Ocorreu um erro ao salvar a imagem.",
          });

          throw error;
        });
    },
    async changePassword(payload: object) {
      return axiosIns
        .put("auth/change_password", payload)
        .then((response) => {
          const notificationStore = useNotificationStore();
          notificationStore.notifySuccess({
            title: "Sucesso",
            text: "Senha alterada com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          console.error("Erro ao fazer alterar senha:", error);
          const notificationStore = useNotificationStore();
          notificationStore.notifyFail({
            title: "Erro",
            text: "Ocorreu um erro ao alterar a senha.",
          });

          throw error;
        });
    },
  },
  getters: {
    userGetter: (state: any) => state.user,
    isValidated: (state: any) => state.user?.validated || false,
    storagedUser: (state: any) =>
      JSON.parse(localStorage.getItem("user") as string),
    userPermission: (state: any) => state.user?.permissions || [],
    isOwner: (state: any) => state.user?.owner || false,
  },
});
