import { createApp } from "vue";

import App from "@/App.vue";
import { registerPlugins } from "@core/utils/plugins";

// Styles
import "@core/scss/template/index.scss";
import "@styles/styles.scss";
import { initializeBugsnag } from "./plugins/bugsnag";
import { notifyBug } from "./utils/notifyBug";
import { vMaska } from "maska/vue";
import { VueDraggableNext } from 'vue-draggable-next'
import { AmplitudeService } from "./services/amplitude";

AmplitudeService.init();
// Create vue app
const app = createApp(App);

const bugSnagApiKey = "0709918e5e0ec2d8bfad9d6575186e3a";
if (bugSnagApiKey) {
  initializeBugsnag(app);
  app.config.errorHandler = (err, vm, info) => {
    notifyBug(err);
    console.error(err);
  };
}
app.component("Draggable", VueDraggableNext)
app.directive("maska", vMaska);
// Register plugins
registerPlugins(app);

// Mount vue app
app.mount("#app");
