import _definePage_default_0 from '/usr/share/nginx/smartdoctors/front/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/usr/share/nginx/smartdoctors/front/src/pages/login.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/index.vue'),
    /* no children */
    meta: {
      "requireAuth": true
    }
  },
  {
    path: '/:all(.*)',
    name: '$all',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth"
    }
  },
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/agenda',
    /* internal name: 'agenda' */
    /* no component */
    children: [
      {
        path: 'confirm',
        name: 'agenda-confirm',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/agenda/confirm.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      },
      {
        path: 'congratulations',
        name: 'agenda-congratulations',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/agenda/congratulations.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      }
    ],
  },
  {
    path: '/auth',
    /* internal name: 'auth' */
    /* no component */
    children: [
      {
        path: 'confirm-code',
        name: 'auth-confirm-code',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/auth/confirm-code.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      },
      {
        path: 'ddiSelector',
        name: 'auth-ddi-selector',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/auth/ddiSelector.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/auth/login.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      },
      {
        path: 'register',
        name: 'auth-register',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/auth/register.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      },
      {
        path: 'reset-password',
        name: 'auth-reset-password',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/auth/reset-password.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      },
      {
        path: 'verify-email',
        name: 'auth-verify-email',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/auth/verify-email.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      }
    ],
  },
  {
    path: '/cadastros',
    /* internal name: 'cadastros' */
    /* no component */
    children: [
      {
        path: 'anamnesis',
        name: 'cadastros-anamnesis',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/cadastros/anamnesis.vue'),
        /* no children */
        meta: {
          "requireAuth": true
        }
      },
      {
        path: 'cadeiras',
        name: 'cadastros-cadeiras',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/cadastros/cadeiras.vue'),
        /* no children */
        meta: {
          "requireAuth": true
        }
      },
      {
        path: 'colaboradores',
        name: 'cadastros-colaboradores',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/cadastros/colaboradores.vue'),
        /* no children */
        meta: {
          "requireAuth": true
        }
      },
      {
        path: 'fornecedores',
        name: 'cadastros-fornecedores',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/cadastros/fornecedores.vue'),
        /* no children */
        meta: {
          "requireAuth": true
        }
      }
    ],
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/calendar.vue'),
    /* no children */
    meta: {
      "requireAuth": true
    }
  },
  {
    path: '/dados-clinica',
    name: 'dados-clinica',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/dados-clinica.vue'),
    /* no children */
    meta: {
      "requireAuth": true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/dashboard.vue'),
    /* no children */
    meta: {
      "requireAuth": true
    }
  },
  {
    path: '/financeiro',
    /* internal name: 'financeiro' */
    /* no component */
    children: [
      {
        path: 'accounts-payable',
        name: 'financeiro-accounts-payable',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/financeiro/accounts-payable.vue'),
        /* no children */
      },
      {
        path: 'accounts-receivable',
        name: 'financeiro-accounts-receivable',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/financeiro/accounts-receivable.vue'),
        /* no children */
      },
      {
        path: 'categories',
        name: 'financeiro-categories',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/financeiro/categories.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/patients',
    /* internal name: 'patients' */
    /* no component */
    children: [
      {
        path: ':patientId',
        name: 'patients-patient-id',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/patients/[patientId].vue'),
        /* no children */
      },
      {
        path: 'list',
        name: 'patients-list',
        component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/patients/list.vue'),
        /* no children */
        meta: {
          "requireAuth": true
        }
      }
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/profile.vue'),
    /* no children */
    meta: {
      "requireAuth": true
    }
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/second-page.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "requireAuth": true
    }
  },
  {
    path: '/specialties',
    name: 'specialties',
    component: () => import('/usr/share/nginx/smartdoctors/front/src/pages/specialties.vue'),
    /* no children */
    meta: {
      "requireAuth": true
    }
  }
]
