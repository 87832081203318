import axios from "axios";
import { router } from "@/plugins/1.router";
import { errorHandler } from "../utils/errorHandler";
import { notifyBug } from "../utils/notifyBug";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${import.meta.env.VITE_BASE_URL}/api`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

axiosIns.interceptors.request.use(
  (config: any) => {
    const storaged = localStorage.getItem("user");
    const user = storaged && JSON.parse(storaged);

    if (user?.token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user.token}`,
      };
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axiosIns.interceptors.response.use(null as any, (error: any) => {
  notifyBug(error);
  return errorHandler(error);
});

function redirectToLogout() {
  router.push("/logout");
}
export default axiosIns;
