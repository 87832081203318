
import socket from '../socketConfig'
import { userStore } from '@/store/user'

const CONTEXT = "professionals";

const calendarHandler = {
  setupEventListeners(): void {
    socket.on(`${CONTEXT}:updatedUser`, () => {
      console.log("user updated");
      const userState = userStore();
      userState.updateUserInfo();
    });
  },

  removeEventListeners(): void {
    socket.off(`${CONTEXT}:updatedUser`);
  }
}

export default calendarHandler
