// src/plugins/bugsnag.ts
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { App } from "vue";

const bugSnagApiKey = "0709918e5e0ec2d8bfad9d6575186e3a";
export function initializeBugsnag(app: App) {
  if (bugSnagApiKey) {
    Bugsnag.start({
      apiKey: bugSnagApiKey,
      plugins: [new BugsnagPluginVue()],
    });

    BugsnagPerformance.start({ apiKey: bugSnagApiKey });
    const bugsnagVue = Bugsnag.getPlugin("vue");
    if (bugsnagVue) {
      app.use(bugsnagVue);
    }
  }
}

export const addUserMetadata = (user: any) => {
  if (user) {
    Bugsnag.addMetadata("user", {
      email: user.email,
      userId: user.userId,
      role: user.role,
      user: user,
    });
  }
};
