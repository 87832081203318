import axios from '@/composables/axios'

export interface Event {
  id: number | null,
  authorId: number | null,
  chairId: number | null,
  status: string | null,
  start: Date | null,
  end: Date | null,
  pacientId: number | null,
  pacientName: string | null,
  allDay: boolean,
  isPersonal: boolean,
  duration: number | null
  professionalIds: number[] | null,
  professionalsInfo: object[] | null,
  procedureId: number | null,
  description: string | null,
  extendedProps: object
};
export interface EventUpdate {
  id: number | null,
  authorId: number | null,
  chairId: number | null,
  status: string | null,
  start: Date | null,
  end: Date | null,
  pacientId: number | null,
  allDay: boolean,
  duration: number | null,
  isPersonal: boolean,
  professionalIds: number[] | null,
  procedureId: number | null,
  description: string | null,
  extendedProps: object
};
export const useCalendarStore = defineStore('calendar', {
  // arrow function recommended for full type inference
  state: () => ({
    availableCalendars: [
      {
        color: 'error',
        label: 'Nº 01',
        value: 1
      },
      {
        color: 'primary',
        label: 'Nº 02',
        value: 2
      },
      {
        color: 'warning',
        label: 'Nº 03',
        value: 3
      },
      {
        color: 'success',
        label: 'Nº 04',
        value: 4
      },
      {
        color: 'info',
        label: 'Nº 05',
        value: 5
      },
    ],
    selectedCalendars: [],
    professionalIds: [],
    patientIds: [] as number[],
    status: ['CONFIRMED', 'CANCELED', 'PENDING', 'ISPERSONAL'],
    events: [],
    updateEvents: false,
    holidays: []
  }),
  actions: {
    async fetchEvents() {
      return axios.get('/calendar', { params: { patientIds: this.patientIds.join(','), chairIds: this.selectedCalendars.join(','), professionalIds: this.professionalIds.join(','), status: this.status } })
        .then((res: any) => this.events = res.data)
        .catch(error => {
          console.error('Error getting patients:', error);
        })
    },
    async addEvent(event: Event) {
      return axios.post('/calendar', event)
    },
    async updateEvent(event: EventUpdate) {
      const { id, ...eventData } = event;
      return axios.patch(`/calendar/${event.id}`, eventData)
    },
    async removeEvent(eventId: string) {
      return axios.delete(`/calendar/${eventId}`)
    },
    createdEvent() {
      // this.fetchEvents()
      this.updateEvents = !this.updateEvents
    },
    updateEventById(id: number, updatedData: Partial<Event>) {
      const eventExists = this.events.some((event: Event) => event.id === id);
      if (eventExists) {
        // Atualiza a lista de eventos imutavelmente
        this.events = this.events.map((event: Event) =>
          event.id === id ? { ...event, ...updatedData } : event
        );
      }
      this.updateEvents = !this.updateEvents
    },
    deleteEventById(id: number) {
      const eventExists = this.events.some((event: Event) => event.id === id);
      if (eventExists) {
        this.events = this.events.filter((event: Event) => event.id !== id);
      }
      this.updateEvents = !this.updateEvents
    },
    /* getHolidays(val: string) {
      const url = `https://brasilapi.com.br/api/feriados/v1/${val}`
      fetch(url)
        .then((res) => res.json())  // Converte a resposta em JSON
        .then((data) => {
          console.log(val);
          this.holidays = data;  // Armazena os feriados na variável holidays
        })
        .catch((error) => {
          console.error('Erro ao buscar os feriados:', error);
        });
    }, */
    getHolidays() {
      axios
        .get("calendar/holidays")
        .then((res) => {
          localStorage.setItem("holidays", JSON.stringify(res?.data));
          this.holidays = res.data;
        })
        .catch((error) => {
          console.error("Erro ao buscar os feriados:", error);
        });
    },
    async setPatientIds(patientIds: number[]) {
      this.patientIds = patientIds
  },

  },
})
