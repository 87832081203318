<template>
    <div class="notifications">
        <div
            class="bg-blue rounded mt-3"
            v-for="notification in notifications"
            :key="notification.uuid"
        >
            <article
                class="message rounded fade-effect"
                :class="context[notification.type]"
                :key="notification.uuid"
            >
                <div class="message-header d-flex justify-content-between w-100">
                    <p>
                        {{ notification.title }}
                    </p>
                    <button
                        class="btn text-black btn-close-notification"
                        @click="closeNotification(notification)"
                        aria-label="delete">
                        &#x2715;
                    </button>
                </div>
                <div class="message-body">
                    {{ notification.text }}
                </div>
            </article>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useNotificationStore, NotificationType  } from '@/store/notification'
import { computed } from "vue"

const notificationStore = useNotificationStore()
const notifications = computed(() => notificationStore.getNotifications);

const context = {
    [NotificationType.SUCCESS]: 'success',
    [NotificationType.WARNING]: 'warning',
    [NotificationType.FAIL]: 'danger'
} as any;

function closeNotification(notificationId: NotificationType) {
    notificationStore.removeNotification(notificationId);
}

</script>
<style scoped lang="scss">
.notifications {
    position: absolute;
    right: 0;
    width: 300px;
    z-index: 9999;
    opacity: 0;
    animation: fade-in .65s ease-in forwards;

    .message {
        padding: 12px;
        background-color: rgb(246, 252, 255);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    .success {
        border-bottom: 2px solid rgb(116, 216, 183);
        p {
            color: darken(rgb(116, 216, 183), 20%);
        }
    }
    .warning {
        border-bottom: 2px solid rgb(255, 204, 0);
        p {
            color: darken(rgb(255, 204, 0), 5%);
        }
    }
    .danger {
        border-bottom: 4px solid rgb(255, 82, 82);
        p {
            color: darken(rgb(255, 82, 82), 5%);
        }

    }

    .message-header {
        position: relative;

        .btn-close-notification {
            position: absolute;
            bottom: 10px;
            right: 0;
        }
    }

    & ~ .notifications {
        animation: slide-up .2s ease-out forwards;
    }
}

.fade-effect {
    transform: scale(0);
    border-radius: 50%;
    opacity: 0;
    overflow: hidden;
    animation: scale-in .3s ease-out forwards,
        expand .35s .25s ease-out forwards,
        fade-out .65s 3.5s ease-out forwards;
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-25px);
    }

    50% {
        transform: translateY(-50px);
    }

    75% {
        transform: translateY(-75px);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale-in {
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes expand {
    50% {
        border-radius: 6px;
    }

    100% {
        border-radius: 4px;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
