import { setupLayouts } from "virtual:generated-layouts";
import type { App } from "vue";

import type { RouteRecordRaw } from "vue-router/auto";

import { createRouter, createWebHistory } from "vue-router/auto";
import websocket from "@/websocket";
import { notifyBug } from "../../utils/notifyBug";

function recursiveLayouts(route: RouteRecordRaw): RouteRecordRaw {
  if (route.children) {
    for (let i = 0; i < route.children.length; i++)
      route.children[i] = recursiveLayouts(route.children[i]);

    return route;
  }

  return setupLayouts([route])[0];
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) return { el: to.hash, behavior: "smooth", top: 60 };

    return { top: 0 };
  },
  // Adicionar redirects e rotas antes de aplicar layouts
  extendRoutes: (pages) => [
    ...[...pages].map((route) => recursiveLayouts(route)), // Rotas e layouts
    {
      path: "/logout",
      name: "logout",
      component: {
        beforeRouteEnter(to, from, next) {
          websocket.disconnect();
          localStorage.clear();
          next("/auth/login");
        },
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const hasToken = JSON.parse(localStorage.getItem("user"))?.token;

  if (to.meta.requireAuth && !hasToken) {
    next("/auth/login");
  } else {
    websocket.connect();
    if (to.fullPath === "/auth/confirm-code" && hasToken) {
      next("/");
    } else {
      next();
    }
  }
});

router.onError((error) => {
  notifyBug(error);
});

export { router };

export default function (app: App) {
  app.use(router);
}
