import { io, Socket } from "socket.io-client";
const baseURL = import.meta.env.VITE_BASE_URL;

const user = localStorage.getItem("user");
const authToken = user ? JSON.parse(user).token : null;

const socket: Socket = io(baseURL, {
  transports: ["polling", "websocket"],
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: authToken,
      },
    },
  },
});

export default socket;
