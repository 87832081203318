import * as amplitude from "@amplitude/analytics-browser";

const API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;
export const AmplitudeService = {
  init() {
    amplitude.init(API_KEY, {
      defaultTracking: true,
    });
  },

  trackEvent(event: string, properties?: Record<string, any>) {
    amplitude.track(event, properties);
  },
};
