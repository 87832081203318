
import socket from '../socketConfig'
import { useCalendarStore } from '@/store/calendar'

const CONTEXT = 'calendar'

const calendarHandler = {
  setupEventListeners(): void {
    socket.on(`${CONTEXT}:created`, () => {
      console.log('Event created')
      const calendarStore = useCalendarStore()
      calendarStore.createdEvent()
    })

    socket.on(`${CONTEXT}:updated`, (status: any) => {
      console.log('Status updated:', status);
      const calendarStore = useCalendarStore();
      calendarStore.updateEventById(status.id, status)
    })

    socket.on(`${CONTEXT}:deleted`, (status: any) => {
      console.log('Event deleted:', status)
      const calendarStore = useCalendarStore()
      calendarStore.deleteEventById(status.id)
    })
  },

  removeEventListeners(): void {
    socket.off(`${CONTEXT}:created`)
    socket.off(`${CONTEXT}:updated`)
    socket.off(`${CONTEXT}:deleted`)
  }
}

export default calendarHandler
